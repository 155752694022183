@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './search_filter.scss';
@import './reports.scss';

@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext');

div,
span,
p,
ion-select,
ion-select-option,
ion-label,
ion-button,
td,
th {
  font-family: Jost !important;
}

.web-view {
  @media screen and (max-width: 720px) {
    display: none !important;
  }
}

.mob-view {
  @media screen and (min-width: 720px) {
    display: none !important;
  }
}

/* Backdrop styles */
.MuiBackdrop-root {
  background: rgba(72, 99, 155, 0.85) !important;
}

/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiFormControl-root,
.MuiOutlinedInput-root {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  border-radius: 4px;
}

.MuiInputBase-root {
  color: var(--input-text-color) !important;
  font-family: 'Jost';
  font-size: 14px;
  height: 48px;
  padding: 0;

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.88) !important;
    background: hsla(0, 0%, 81.2%, 0.3) !important;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 16px;
    font-family: 'Jost';
    padding: 14px 16px;
  }

  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

.border-input {
  background: #eaeef3;
  border: 1px solid #eaeef3;
  border-radius: 8px;
}

.input-label {
  color: var(--ion-text-color);
  font-family: 'Jost';
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;

  @media screen and (max-width: 720px) {
    padding-bottom: 2px;
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
}

.MuiTab-root {
  text-transform: none;
}

.MuiTabs-indicator {
  display: none;
}

/** Modal styles **/
.modal-title {
  .MuiTypography-root {
    font-family: var(--title-font-family) !important;
    font-size: 20px;
  }
}

.modal-content-ctn {
  width: 100%;
  padding: 0px !important;
  background-color: #ffffff;
  background: #ffffff !important;

  .input-control {
    border: 1px solid var(--filter-controls-border);
  }

  .num-control input:focus {
    outline: none;
  }
}

.mouse-pointer {
  cursor: pointer;
}

/* Punter reports styles */
.admin-module-ctn {
  // padding-left: 30px;
  // padding-right: 30px;
  background-color: #f6f9ff;
  padding-bottom: 50px;
}

.br-16 {
  border-radius: 16px;
}

.full-width {
  width: 100%;
}

.no-txt-wrap {
  white-space: nowrap !important;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.b-text {
  font-weight: 600 !important;
}
.link-text {
  font-weight: 600 !important;
  color: var(--ion-text-color);
  text-decoration: none;
}

.profit,
.success {
  color: var(--ion-color-success);
}

.loss,
.error-msg {
  color: var(--ion-color-danger);
}

.m-link {
  cursor: pointer !important;
}

.popover-content.sc-ion-popover-md {
  background: var(--ion-header-background);
  border: 2px solid var(--ion-button-border);
  border-radius: 10px;

  ion-select-popover ion-list ion-item.item-radio-checked {
    background: var(--ion-header-background);
    color: var(--ion-color-primary) !important;
  }

  .popover-viewport.sc-ion-popover-md {
    background: var(--ion-header-background);

    ion-item {
      font-family: Jost;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      background-color: var(--ion-background);

      ion-label {
        color: #fff;
      }

      &:hover {
        background-color: var(--ion-header-background);
        --color: var(--ion-color-primary) !important;
      }
    }
  }

  .nav-link {
    color: white;

    &.active {
      ion-item {
        color: var(--ion-color-primary) !important;
        border-left: 4px solid var(--ion-color-primary);
      }
    }

    &:hover {
      color: var(--ion-color-primary) !important;
    }
  }

  ion-item {
    font-family: Jost;
    color: white;

    &:hover {
      background-color: var(--ion-header-background);
      color: var(--ion-color-primary) !important;
    }
  }
}

@media only screen and (min-width: 720px) {
  .mobile-footer {
    display: none;
  }
}

.popover-viewport.sc-ion-popover-ios {
  background: var(--ion-header-background);
}

.brand-logo {
  //position: absolute;
  top: 7%;
  right: 0;
  left: 0;
  margin: 40px auto 0;
  width: fit-content;

  @media screen and (max-width: 1280px) {
    top: 1%;
  }

  @media screen and (max-width: 1140px) {
    top: 1.5%;
  }

  @media screen and (max-width: 920px) {
    top: 14%;
  }

  @media screen and (min-width: 1400px) {
    top: 5%;
  }

  .brand-logo-img {
    width: var(--login-xl-logo-width);

    @media screen and (max-width: 720px) {
      width: var(--login-sm-logo-width);
    }
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.primary-text-color {
  color: var(--ion-color-primary);
}

.text-red {
  color: red;
}

.d-flex {
  display: flex !important;

  @media screen and (max-width: 720px) {
    &.mob-view {
      display: flex !important;
    }
  }
}

.mr-2 {
  margin-right: 2rem;
}

.set-pswd-modal {
  &.MuiDialog-root
    .MuiDialog-container
    .MuiDialog-paper
    .MuiDialogContent-root {
    background-color: #fff;

    .MuiIconButton-root {
      top: 0;
    }

    .MuiFormControl-root {
      border: 0;
      border-radius: 10px;
      background-color: unset;
    }
    .MuiInputBase-root {
      background-color: unset;
      border-radius: 10px;

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }

      .MuiInputBase-input {
        border-radius: 10px;

        background-color: #f1f1f1;
      }
    }
  }

  .MuiPaper-root {
    border-radius: 30px;

    @media screen and (max-width: 720px) {
      border-radius: 0;
    }
  }
}

.set-pswd-btn.submit-form-btn {
  width: 100%;
  font-size: 24px;

  @media screen and (max-width: 720px) {
    font-size: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-primary {
  color: var(--ion-color-primary) !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}